.topo__cima{
	padding: 8px 0px;

	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.cima__vindo{
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 18px;
	color: #232222;
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.menu__link{
	font-size: 13px;
	line-height: 13px;
	font-family: 'montserratsemibold';
}
@include media-breakpoint-up (lg) {
	.topo__main{
		box-shadow: 0px 0px 95px 20px rgba(#000, 0.11);
		padding: 10px 0px;

		.container{
			display: flex;
			justify-content: space-between;
		}
	}
	.main__menu{
		display: flex;
		justify-content: space-around;
		flex-grow: 1;
	}
	.main__sociais{
		display: none;
	}
	.menu__item{
		display: flex;

		&.menu__item--active{
			.menu__link{
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.menu__link{
		display: flex;
		align-items: center;
		position: relative;

		&:hover{
			text-decoration: none;

			&:after{
				opacity: 1;
			}
		}

		&:after{
			content: '';
			width: 90%;
			height: 3px;
			background-color: #f71717;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: calc(50% + 9px);
			transition: all 0.3s linear;
			opacity: 0;
		}
	}
	.main__logo{
		margin-right: 40px;
	}
	.cima__sociais{
		display: flex;

		a{
			font-size: 24px;
			line-height: 24px;
			color: #000;
			transition: all 0.3s linear;
			display: block;
			margin: 0px 10px;

			&:hover{
				text-decoration: none;
				color: #920b0b;
			}
		}
	}
	.topo__mbl{
		display: none;
	}
}
@include media-breakpoint-only (lg) {
	.main__logo{
		margin-right: 30px;
		max-width: 200px;
	}
	.main__menu{
		justify-content: space-between;
	}
}
@include media-breakpoint-down (md) {
	.topo__cima{
		.container{
			justify-content: center;
		}
	}
	.cima__sociais{
		display: none;
	}
	.topo__mbl{
		padding: 10px 0px;
		box-shadow: 0px 0px 95px 20px rgba(#000, 0.11);

		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		font-size: 30px;
		line-height: 30px;

		&:focus{
			outline: none;
		}
	}
	.topo__main{
		position: fixed;
		z-index: 1000;
		left: -250px;
		top: 0px;
		height: 100%;
		width: 250px;
		overflow-y: auto;
		transition: all 0.4s linear;
		background-color: #fff;

		.container{
			padding: 0px;
		}

		&.topo__main--shown{
			left: 0px;
		}
	}
	.bg__menu{
		position: fixed;
		top: 0px;
		left: 0px;
		background-color: rgba(#000, 0.7);
		width: 100%;
		height: 100%;
		animation: fadeIn 0.4s linear;
		z-index: 999;

		&.hide{
			animation: fadeOut 0.4s linear;
		}
	}
	.main__logo{
		display: block;
		padding: 10px;
		margin-bottom: 10px;
	}
	.menu__link{
		display: block;
		padding: 10px 10px 5px 10px;
		position: relative;

		&:hover{
			text-decoration: none;

			&:after{
				opacity: 1;
			}
		}

		&:after{
			height: 100%;
			width: 10px;
			background-color: #f71717;
			content: '';
			position: absolute;
			right: 0px;
			top: 0px;
			transition: all 0.3s linear;
			opacity: 0;
		}
	}
	.menu__item{
		margin-bottom: 5px;
	}
	.main__menu{
		margin-bottom: 20px;
	}
	.main__sociais{
		display: flex;
		justify-content: center;

		a{
			display: block;
			margin: 0px 15px;
			font-size: 20px;
			line-height: 20px;
			transition: all 0.3s linear;

			&:hover{
				color: #920b0b;
			}
		}
	}
}
@include media-breakpoint-down (sm) {
	.mbl__logo{
		display: block;
		max-width: 170px;
	}
}
