#clientes-e-parceiros-mod-2{
	padding-top: 100px;

	.row.clientes{
		align-items: center;
	}

	.paginacao{
		.active{
			background: #FFB554;
			color: #000;
		}
	}

	.titles{
		font-family: 'montserratbold';

		&:before{
			content:'';
			display: inline-block;
			width:1em;
			height:0.2em;
			margin-bottom:0.2em;
			background: currentcolor;
			margin-right:0.5em;
		}
	}
}
