@font-face {
    font-family: 'typoslab';
    src: url('../fonts/TypoSlabIrregular/TypoSlabIrregularDemo.eot');
    src: url('../fonts/TypoSlabIrregular/TypoSlabIrregularDemo.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TypoSlabIrregular/TypoSlabIrregularDemo.woff2') format('woff2'),
         url('../fonts/TypoSlabIrregular/TypoSlabIrregularDemo.woff') format('woff'),
         url('../fonts/TypoSlabIrregular/TypoSlabIrregularDemo.ttf') format('truetype'),
         url('../fonts/TypoSlabIrregular/TypoSlabIrregularDemo.svg#TypoSlabIrregularDemo') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'arial';
    src: url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.eot');
    src: url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.woff2') format('woff2'),
         url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.woff') format('woff'),
         url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.ttf') format('truetype'),
         url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.svg#ArialRoundedMTBold') format('svg');
    font-weight: bold;
    font-style: normal;
}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 8, 2019 */



@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('../fonts/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 14, 2019 */



@font-face {
    font-family: 'montserratmedium';
    src: url('../fonts/montserrat-medium/montserrat-medium-webfont.eot');
    src: url('../fonts/montserrat-medium/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.woff') format('woff'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 8, 2019 */



@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat-regular/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat-regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 14, 2019 */



@font-face {
    font-family: 'montserratsemibold';
    src: url('../fonts/montserrat-semibold/montserrat-semibold-webfont.eot');
    src: url('../fonts/montserrat-semibold/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.woff') format('woff'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.svg#montserratsemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotoregular';
    font-display: optional;
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot');
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
