.rodape{
	background-image: url('../images/rodape__bg.jpg');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	color: #fff;
	border-top: 18px solid #f71717;
}
.rodape__conteudo{
	padding: 30px 0px 20px 0px;
}
.rodape__logo{
	margin-bottom: 20px;
}
.rodape__desc{
	text-align: justify;
	font-size: 12px;
	line-height: 16px;
	color: #aeaeae;
}
.rodape__titulo{
	font-family: 'montserratsemibold';
	font-size: 15px;
	line-height: 19px;
	color: #ffffff;
	margin-bottom: 10px;
}
.rodape__menu{
	font-size: 13px;
	line-height: 17px;
	color: #b7b7b7;

	a{
		display: block;
		padding: 2px 0px;
	}
}
.rodape__fone{
	flex-direction: column;
	display: flex;
	margin-bottom: 16px;

	span{
		margin-bottom: 5px;
		font-size: 13px;
		line-height: 17px;
		color: #b7b7b7;
	}
	a{
		font-family: 'montserratsemibold';
		font-size: 15px;
		line-height: 19px;
	}
}
.rodape__fones{
	display: flex;
	justify-content: space-between;
}
.rodape__email{
	display: flex;
	flex-direction: column;

	span{
		margin-bottom: 5px;
		font-size: 15px;
		line-height: 19px;
		font-family: 'montserratsemibold';
	}
	a{
		color: #b7b7b7;
		font-size: 13px;
		line-height: 17px;
	}
}
.rodape__mapa{
	.lazy-embed{
		--width: 390;
		--height: 200;
		margin-right: auto;
		margin-left: auto;
	}
}
.mapa__header{
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 18px;
	color: #fff;
	font-family: 'montserratsemibold';
	align-items: center;
}
.whats__btn{
	position: fixed;
	right: 10px;
	bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #ff0000;
	color: #fff;
	font-size: 32px;
	line-height: 32px;
	z-index: 998;

	&:hover{
		text-decoration: none;
	}
}
.rodape__creditos{
	padding: 8px 0px;
	text-align: center;
	color: #fff;
	font-size: 10px;
	line-height: 14px;
}
.gv8__box{
	display: flex;
	align-items: center;
	justify-content: center;

	.logo__gv8{
		margin-left: 5px;
	}
}
.mapa__sociais{
	color: #920b0b;
	font-size: 21px;
	line-height: 21px;
	display: flex;

	a{
		display: block;
		margin: 0px 10px;
	}
}
@include media-breakpoint-only (lg) {
	.rodape__fones{
		flex-direction: column;
	}
}
@include media-breakpoint-down (md) {
	.rodape__logo{
		text-align: center;
	}
	.rodape__desc{
		text-align: center;
		margin-bottom: 10px;
	}
	.rodape__titulo{
		text-align: center;
	}
	.rodape__menu{
		text-align: center;
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
	}
	.rodape__fones{
		flex-direction: column;
		align-items: center;
	}
	.rodape__fone,
	.rodape__email{
		align-items: center;
	}
	.mapa__header{
		padding-top: 20px;
		flex-direction: column;

		span{
			order: 2;
		}
		.mapa__sociais{
			order: 1;
			margin-bottom: 15px;

			a{
				color: #fff;
			}
		}
	}
}
