.banner__carousel{
	max-width: 1920px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;
	position: relative;
}
.banner__inner{
	max-width: 1920px;
	width: 160%;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
.banner__indicators{
	li{
		width: 40px;
		height: 6px;
		background-color: #898787;
		transition: all 0.3s linear;

		&.active{
			background-color: #ff0000;
		}
	}
}
.detalhes{
	padding: 24px 0px 18px 0px;
	color: #fff;
	background-color: #f71717;
}
.detalhe__card{
	display: flex;
	align-items: flex-start;
}
.detalhe__img{
	margin-right: 10px;
}
.detalhe__desc{
	font-size: 12px;
	line-height: 16px;
	color: rgba(#fff, 0.64);
}
.detalhe__titulo{
	margin-bottom: 5px;
	font-family: 'montserratsemibold';
	font-size: 15px;
	line-height: 19px;
}
.titulo__quem{
	font-family: 'montserratmedium';
	font-size: 14px;
	line-height: 18px;
	color: #1f1f1f;
	position: relative;
	margin-bottom: 20px;

	&:before{
		width: 38px;
		height: 4px;
		content: '';
		position: absolute;
		top: calc(100% + 3px);
		left: 0px;
		background-color: #f71717;
	}
}
.quem__somos{
	overflow: hidden;
}
.sub__quem{
	font-family: 'typoslab';
	font-size: 22px;
	line-height: 26px;
	color: #232323;
	margin-bottom: 14px;
}
.quem__somos__desc{
	text-align: justify;
	font-size: 13px;
	line-height: 17px;
	color: #1f1f1f;
	margin-bottom: 14px;
}
.quem__item__btn{
	position: relative;
	font-family: 'montserratsemibold';
	font-size: 16px;
	line-height: 20px;
	background-color: transparent;
	border: none;
	margin-bottom: 6px;

	&[aria-expanded="true"]{
		.btn__expandir{
			&:before{
				height: 4px;
			}
		}
	}

	&:focus{
		outline: none;
	}
}
.btn__expandir{
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	content: '';
	position: absolute;
	top: calc(50% - 17px);
	left: -40px;
	border: 5px solid #f71717;
	border-radius: 50%;

	&:after{
		content: '';
		position: absolute;
		width: 18px;
		height: 4px;
		background-color: #f71717;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 2px;
	}
	&:before{
		content: '';
		position: absolute;
		height: 18px;
		width: 4px;
		background-color: #f71717;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 2px;
		transition: all 0.3s linear;
	}
}
.quem__item{
	padding-left: 40px;
	margin-bottom: 20px;

	.collapse{
		font-size: 13px;
		line-height: 17px;
		text-align: justify;
	}
}
.btn__conteudo{
	border: 3px solid #f71717;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	padding: 12px 30px 11px 30px;
	display: inline-flex;
	font-family: 'montserratbold';
	font-size: 12px;
	line-height: 12px;
	color: #f71717;
	transition: all 0.3s linear;
	background-color: transparent;

	&:hover{
		background-color: #f71717;
		color: #fff;
		text-decoration: none;
	}
	&:focus{
		outline: none;
	}
}
.segura__quem__btn{
	display: flex;
	justify-content: flex-end;
}
.servico__item__conteudo{
	background-color: #a20000;
	color: #fff;
}
.servicos{
	background-color: #272727;
	color: #fff;
	padding: 26px 0px 28px 0px;
}
.servico__titulo{
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 18px;
	position: relative;
	margin-bottom: 20px;
	text-align: center;

	&:before{
		width: 38px;
		height: 4px;
		content: '';
		position: absolute;
		top: calc(100% + 3px);
		left: 50%;
		transform: translateX(-50%);
		background-color: #f71717;
	}
}
.servico__desc{
	text-align: center;
	max-width: 800px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 15px;
}
.servicos__owl.owl-carousel{
	.owl-dots{
		display: none;
	}
	.owl-nav{
		z-index: 120;

		button.owl-prev,
		button.owl-next{
			top: 50%;
			transform: translateY(-50%);
			font-size: 26px;
			line-height: 26px;
			color: #f71717;
			position: absolute;
			z-index: 150;

			&:focus{
				outline: none;
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
}
.servico__item__titulo{
	font-family: 'arial';
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 26px;
}
.servico__item__desc{
	&::-webkit-scrollbar{
		width: 8px;
	}
	&::-webkit-scrollbar-track{
		background-color: rgba(#f71717, 0.2);
		border-radius: 4px;
	}
	&::-webkit-scrollbar-thumb{
		background-color: rgba(#1f1f1f, 0.5);
		border-radius: 4px;
	}
}
.parceiros{
	padding: 30px 0px 15px 0px;
}
.parceiros__titulo{
	font-family: 'montserratbold';
	margin-bottom: 40px;
	position: relative;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #a20000;

	&:before{
		content: '';
		position: absolute;
		width: 38px;
		height: 5px;
		background-color: #272727;
		top: calc(100% + 6px);
		left: 50%;
		transform: translateX(-50%);
	}
}
.parceiro__card{
	display: block;
	max-width: 200px;
	margin-right: auto;
	margin-left: auto;
	filter: grayscale(1);
	transition: all 0.3s linear;

	&:hover{
		filter: grayscale(0);
	}
}
.parceiros__owl.owl-carousel{
	padding: 0px 25px;

	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 26px;
			line-height: 26px;
			color: #f71717;
			z-index: 150;

			&:focus{
				outline: none;
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
}
@include media-breakpoint-up (lg) {
	.quem__somos{
		.row{
			align-items: center;

			.col-lg-6{
				&:last-child{
					padding-top: 30px;
					padding-bottom: 30px;
				}
			}
		}
	}
	.servico__item{
		display: flex;
		max-width: 292px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		overflow: hidden;
		transition: all 0.4s linear;
		position: relative;

		&:after{
			content: '';
			position: absolute;
			bottom: 16px;
			right: 25px;
			width: 5px;
			height: 27px;
			background-color: #f71717;
			transition: all 0.3s linear;
			border-radius: 2px;
			z-index: 10;
		}
		&:before{
			content: '';
			position: absolute;
			right: 14px;
			bottom: 27px;
			width: 27px;
			height: 5px;
			background-color: #f71717;
			transition: all 0.3s linear;
			border-radius: 2px;
			z-index: 10;
		}

		&:hover{
			text-decoration: none;

			&:before,
			&:after{
				background-color: #1f1f1f;
			}
		}

		.servico__item__img{
			width: 292px;
			height: 288px;
		}
		.servico__item__conteudo{
			width: 292px;
			height: 288px;
			min-width: 292px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: 40px 30px 50px 20px;
		}
	}
	.servicos__owl.owl-carousel{
		max-width: 1224px;
		margin-right: auto;
		margin-left: auto;

		&:before{
			z-index: 100;
			content: '';
			width: 20px;
			right: -2px;
			top: 0px;
			height: 100%;
			position: absolute;
		}

		.owl-stage-outer{
			&:hover{
				.owl-item{
					&.active{
						&:not(:hover){
							width: 320px !important;
						}
					}
				}
			}
		}
		.owl-item.active{
			transition: all 0.4s linear;

			&:hover{
				width: 584px !important;

				.servico__item{
					max-width: 584px;
				}
			}
		}
	}
	.servico__item__desc{
		max-height: 136px;
		overflow-y: auto;
		font-size: 13px;
		line-height: 17px;
		text-align: justify;
		padding-right: 5px;
	}
}
@include media-breakpoint-only (lg) {
	.servicos__owl.owl-carousel{

		&:before{
			z-index: 100;
			content: '';
			width: 30px;
			right: -2px;
			top: 0px;
			height: 100%;
			position: absolute;
		}
	}
}
@include media-breakpoint-down (md) {
	.detalhe__card{
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-bottom: 25px;

		.detalhe__img{
			margin-right: 0px;
			margin-bottom: 10px;
		}
	}
	.quem__somos{
		padding: 40px 0px 30px 0px;
		.row{
			.col-lg-6{
				&:first-child{
					order: 2;
				}
				&:last-child{
					order: 1;
					margin-bottom: 15px;
				}
			}
		}
	}
	.servico__item{
		display: block;
		max-width: 292px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
	.servico__item__conteudo{
		min-height: 288px;
		max-height: 288px;
		height: 288px;
		padding: 30px 25px 15px 25px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.servico__item__desc{
		overflow-y: auto;
		max-height: 180px;
	}
}
