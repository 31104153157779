.trabalhe__interna{
	padding: 130px 0px 96px 0px;
}
.titulo__interna{
	font-family: 'typoslab';
	font-size: 30px;
	line-height: 34px;
	text-align: center;
	margin-bottom: 30px;
}
.segura__input{
	margin-bottom: 10px;
}
.trabalhe__input{
	width: 100%;
	display: block;
	height: auto;
	padding: 12px 20px 10px 20px;
	border: 2px solid #777777;
	font-family: 'montserratmedium';
	font-size: 14px;
	line-height: 18px;
	color: #1f1f1f;
	resize: none;

	&::placeholder{
		color: #1f1f1f;
	}

	&:focus{
		outline: none;
	}
}
.trabalhe__form{
	max-width: 926px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.trabalhe__btn{
	display: flex;
	justify-content: center;
	padding-top: 20px;
}
